import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const Sandwich = () => {
	const [specials, setSpecials] = useState([]);
	useEffect(() => {
		const go = async () => {
			const query = `{
      specials(where:{is_active:{_eq:true}}) {
        title
        price
        is_new
        description
        allergies {
          allergy {
            name
          }
        }
      }
    }`;
			const res = await fetch(
				'https://monkeys-specials.herokuapp.com/v1/graphql',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
					body: JSON.stringify({
						query,
					}),
				}
			).catch(console.error);

			const response = await res.json();
			setSpecials(
				response.data.specials.map((s) => ({
					title: s.title,
					is_new: s.is_new,
					price: s.price,
					description: s.description,
					allergies: s.allergies.map((a) => a.allergy.name),
				}))
			);
		};
		go();
	}, []);

	return specials
		.sort((a, b) => {
			let first = a.title.toLowerCase();
			let second = b.title.toLowerCase();
			return first < second ? -1 : first > second ? 1 : 0;
		})
		.map((special) => (
			<li key={special.title}>
				<h3>
					{special.title}
					{special.is_new ? (
						<img className="new-item-callout" src="/img/Menu-Call-Out.png" />
					) : null}
					<span className="food-price">{special.price}</span>
				</h3>
				<div className="desc-div">{special.description}</div>
				<div className="allergy-icons">
					{special.allergies.includes('mustard') && (
						<img
							src="/img/allergy-icons/FM_Allergen_Icons_0000_Mustard.png"
							alt="Contains mustard"
							title="Contains mustard"
						/>
					)}
					{special.allergies.includes('sesame_seeds') && (
						<img
							src="/img/allergy-icons/FM_Allergen_Icons_0001_Sesame-Seeds.png"
							alt="Contains sesame seeds"
							title="Contains sesame seeds"
						/>
					)}
					{special.allergies.includes('sulphites') && (
						<img
							src="/img/allergy-icons/FM_Allergen_Icons_0002_Sulphites.png"
							alt="Contains sulphites"
							title="Contains sulphites"
						/>
					)}
					{special.allergies.includes('soy') && (
						<img
							src="/img/allergy-icons/FM_Allergen_Icons_0003_Soy.png"
							alt="Contains soy"
							title="Contains soy"
						/>
					)}
					{special.allergies.includes('wheat') && (
						<img
							src="/img/allergy-icons/FM_Allergen_Icons_0004_Wheat.png"
							alt="Contains wheat"
							title="Contains wheat"
						/>
					)}
					{special.allergies.includes('tree_nuts') && (
						<img
							src="/img/allergy-icons/FM_Allergen_Icons_0005_Tree-Nuts.png"
							alt="Contains tree nuts"
							title="Contains tree nuts"
						/>
					)}
					{special.allergies.includes('shellfish') && (
						<img
							src="/img/allergy-icons/FM_Allergen_Icons_0006_Shell-Fish.png"
							alt="Contains shellfish"
							title="Contains shellfish"
						/>
					)}
					{special.allergies.includes('fish') && (
						<img
							src="/img/allergy-icons/FM_Allergen_Icons_0007_Fish.png"
							alt="Contains fish"
							title="Contains fish"
						/>
					)}
					{special.allergies.includes('peanuts') && (
						<img
							src="/img/allergy-icons/FM_Allergen_Icons_0008_Peanuts.png"
							alt="Contains peanuts"
							title="Contains peanuts"
						/>
					)}
					{special.allergies.includes('eggs') && (
						<img
							src="/img/allergy-icons/FM_Allergen_Icons_0009_Eggs.png"
							alt="Contains eggs"
							title="Contains eggs"
						/>
					)}
					{special.allergies.includes('milk') && (
						<img
							src="/img/allergy-icons/FM_Allergen_Icons_0010_Milk.png"
							alt="Contains milk"
							title="Contains milk"
						/>
					)}
				</div>
			</li>
		));
};

export default Sandwich;

import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';

import TaproomSection from '../components/taproom/taproom-section';

export const query = graphql`
	query {
		prismicTaproomPage {
			data {
				left_column_details {
					html
					text
					raw
				}
				right_column_details {
					html
					text
					raw
				}
				on_tap_section_heading
				on_tap_detail_heading
				on_tap_detail_text {
					html
				}
				food_section_heading
				food {
					item_visibility
					menu_item {
						id
						isBroken
						document {
							... on PrismicFood {
								data {
									menu_item_name
									is_item_new
									menu_item_price
									menu_item_description {
										text
									}
									mustard
									sesame_seeds
									sulphites
									soy
									wheat
									tree_nuts
									shellfish
									fish
									peanuts
									eggs
									milk
								}
							}
						}
					}
				}
				show_secondary_menu
				secondary_menu_title {
					text
				}
				auxiliary_menu {
					item_visibility
					menu_item {
						document {
							... on PrismicFood {
								data {
									menu_item_name
									is_item_new
									menu_item_price
									menu_item_description {
										text
									}
									mustard
									sesame_seeds
									sulphites
									soy
									wheat
									tree_nuts
									shellfish
									fish
									peanuts
									eggs
									milk
								}
							}
						}
					}
				}
				fridge_section_heading
				fridge_list {
					fridge_beer_image {
						url
						localFile {
							publicURL
						}
					}
					fridge_beer_abv {
						text
					}
					fridge_beer_name {
						text
					}
					fridge_beer_description {
						text
					}
					is_item_new
				}
				tap_list {
					tap_beer_icon {
						url
						localFile {
							publicURL
						}
					}
					tap_beer_name {
						text
					}
					tap_beer_abv {
						text
					}
					tap_beer_description {
						text
					}
				}
				use_odoo_tap_list
				seltzer_section_heading
				seltzers {
					item_visibility
					seltzer {
						id
						isBroken
						document {
							... on PrismicSeltzer {
								data {
									name
									is_item_new
									image {
										alt
										url
									}
									abv
									short_description
									description
									buy_now_link {
										isBroken
										url
										target
									}
									buy_now_link_visibility
								}
							}
						}
					}
				}
			}
		}
		allTapList {
			edges {
				node {
					beers {
						name
						abv
						desc
						icon
					}
				}
			}
		}
	}
`;

const PageTemplate = ({ data }) => {
	const headings = {
		tapSectionHeading: data.prismicTaproomPage.data.on_tap_section_heading,
		seltzerSectionHeading: data.prismicTaproomPage.data.seltzer_section_heading,
		fridgeSectionHeading: data.prismicTaproomPage.data.fridge_section_heading,
		foodSectionHeading: data.prismicTaproomPage.data.food_section_heading,
	};

	const leftColumnDetails = data.prismicTaproomPage.data.left_column_details;
	const rightColumnDetails = data.prismicTaproomPage.data.right_column_details;
	const useOdooTapList = data.prismicTaproomPage.data.use_odoo_tap_list;
	const onTapDetails = {
		heading: data.prismicTaproomPage.data.on_tap_detail_heading
			? data.prismicTaproomPage.data.on_tap_detail_heading
			: undefined,
		text:
			data.prismicTaproomPage.data.on_tap_detail_text &&
			data.prismicTaproomPage.data.on_tap_detail_text.html !== '<p></p>'
				? data.prismicTaproomPage.data.on_tap_detail_text.html
				: undefined,
	};
	const odooBeers = data.allTapList.edges[0].node.beers;
	const beerList =
		useOdooTapList && odooBeers.length
			? odooBeers
			: data.prismicTaproomPage.data.tap_list.map((beer) => ({
					name: beer.tap_beer_name.text,
					desc: beer.tap_beer_description.text,
					abv: beer.tap_beer_abv.text,
					icon: beer.tap_beer_icon.url,
			  }));

	const seltzerList = data.prismicTaproomPage.data.seltzers
		.map((item) => {
			const { id, isBroken } = item.seltzer;

			if (id && isBroken === false) {
				const { data: seltzer } = item.seltzer.document;

				return {
					id,
					isVisible: item.item_visibility,
					name: seltzer.name,
					isNew: seltzer.is_item_new,
					image: seltzer.image,
					abv: seltzer.abv,
					shortDescription: seltzer.short_description,
					buyNowLink: seltzer.buy_now_link,
					buyNowLinkIsVisible: seltzer.buy_now_link_visibility,
				};
			}

			return null;
		})
		.filter((item) => item);

	const fridgeList = data.prismicTaproomPage.data.fridge_list.map((beer) => ({
		name: beer.fridge_beer_name.text,
		desc: beer.fridge_beer_description.text,
		abv: beer.fridge_beer_abv.text,
		icon: beer.fridge_beer_image.url,
		new: beer.is_item_new,
	}));
	const foodList = data.prismicTaproomPage.data.food
		.map((item) => {
			const { id, isBroken } = item.menu_item;

			if (id && !isBroken) {
				const { data: menuItem } = item.menu_item.document;

				return {
					isVisible: item.item_visibility,
					title: menuItem.menu_item_name,
					price: menuItem.menu_item_price,
					desc: menuItem.menu_item_description.text,
					new: menuItem.is_item_new,
					mustard: menuItem.mustard,
					sesame_seeds: menuItem.sesame_seeds,
					sulphites: menuItem.sulphites,
					soy: menuItem.soy,
					wheat: menuItem.wheat,
					tree_nuts: menuItem.tree_nuts,
					shellfish: menuItem.shellfish,
					fish: menuItem.fish,
					peanuts: menuItem.peanuts,
					eggs: menuItem.eggs,
					milk: menuItem.milk,
				};
			}

			return null;
		})
		.filter((item) => item);

	const secondaryMenu = data.prismicTaproomPage.data.auxiliary_menu
		.map((item) => {
			// not sure why this is here, but the menu items do not have an isBroken property.
			// disabled this to get secondary menu working again.
			//const { id, isBroken } = item.menu_item;
			//if (id && !isBroken) {

			const { data: menuItem } = item.menu_item.document;

			return {
				isVisible: item.item_visibility,
				title: menuItem.menu_item_name,
				price: menuItem.menu_item_price,
				desc: menuItem.menu_item_description.text,
				new: menuItem.is_item_new,
				mustard: menuItem.mustard,
				sesame_seeds: menuItem.sesame_seeds,
				sulphites: menuItem.sulphites,
				soy: menuItem.soy,
				wheat: menuItem.wheat,
				tree_nuts: menuItem.tree_nuts,
				shellfish: menuItem.shellfish,
				fish: menuItem.fish,
				peanuts: menuItem.peanuts,
				eggs: menuItem.eggs,
				milk: menuItem.milk,
			};
			//}
			//return null;
		})
		.filter((item) => item);

	const showSecondaryMenu =
		data.prismicTaproomPage.data.show_secondary_menu &&
		secondaryMenu.some((item) => item.isVisible)
			? true
			: false;
	const secondaryMenuTitle =
		data.prismicTaproomPage.data.secondary_menu_title.text;

	console.log(data.prismicTaproomPage.data);

	return (
		<Layout>
			<SEO title="Taproom" />
			<TaproomSection
				headings={headings}
				leftColumn={leftColumnDetails}
				rightColumn={rightColumnDetails}
				onTapDetails={onTapDetails}
				beerList={beerList}
				seltzerList={seltzerList}
				fridgeList={fridgeList}
				foodList={foodList}
				secondaryMenu={secondaryMenu}
				useOdooTapList={useOdooTapList}
				showSecondaryMenu={showSecondaryMenu}
				secondaryMenuTitle={secondaryMenuTitle}
			/>
		</Layout>
	);
};

export default withPreview(PageTemplate);

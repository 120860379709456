import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import mdToHtml from '../../../util/mdToHtml';

import Button from '../../../components/shared/button';

import taproomBg from './images/taproom-bg.jpg';
import couch from './images/couch.jpg';

import Sandwich from './sandwich';

const StyledSection = styled.section`
	background-image: url(${taproomBg});
	background-size: 100%;
	background-attachment: fixed;
	padding-top: 250px;
	@media (max-width: 999px) {
		padding-top: 125px;
	}
	h1 {
		font-size: 3rem;
		text-align: center;
		margin-bottom: 2rem;
	}
	.details {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		font-size: 1.25rem;
		table {
			margin-bottom: 2rem;

			th {
				color: white;
				padding-right: 1rem;

				p {
					text-align: center;
					color: white;
				}
			}
		}
		p {
			text-align: center;
			font-weight: 700;
			color: ${(p) => p.theme.color.yellow};
			strong {
				color: white;
			}
		}
		@media (max-width: 999px) {
			flex-direction: column;
			th,
			td {
				vertical-align: middle;
				text-align: center;
				padding: 0.5rem 0;
				font-size: 1rem;
			}
			p {
				text-align: center;
				font-size: 1rem;
			}
			table {
				margin-bottom: 2rem;

				th {
					p {
						text-align: center;
					}
				}
			}
		}
	}
	.constrain {
		max-width: 1024px;
		margin: 0 auto;
	}
	.panel {
		background-color: rgba(0, 0, 0, 0.9);
		padding: 2rem;
		color: white;
		h2 {
			font-size: 2.5rem;
			text-align: center;
			padding-bottom: 1rem;
			margin: 0 1rem;
			letter-spacing: 3px;
		}
		@media (max-width: 999px) {
			padding: 0.5rem;
			border-right-width: 0;
			border-left-width: 0;
		}
	}
	.food-list {
		h3 {
			font-size: 2rem;
		}
		.new-item-callout {
			position: relative;
			width: 64px;
			height: auto;
		}
		.food-price {
			font-size: 1.25rem;
			font-style: italic;
			font-family: ${(p) => p.theme.font.body};
			margin-left: 1rem;
		}
		p {
			font-size: 1.125rem;
			line-height: 1.5;
		}
		li {
			margin-bottom: 1rem;
			padding: 0 1rem;
		}
		@media (max-width: 999px) {
			h3 {
				font-size: 1.5rem;
			}
			.food-price {
				font-size: 1rem;
			}
			p {
				font-size: 1rem;
			}
		}
	}
	.fridge {
		.new-item-callout {
			position: relative;
			width: 64px;
			height: auto;
		}
		.beer-glass {
			margin: 0 8px;
		}
	}
	.beer-cols {
		display: flex;
		ul {
			flex-basis: 50%;
			padding: 1rem;
			@media (max-width: 999px) {
				padding-bottom: 0;
				padding-top: 0;
			}
		}
		@media (max-width: 999px) {
			flex-direction: column;
			.beer-list {
				li {
					text-align: center;
				}
			}
		}
	}
	li {
		margin-bottom: 0.5rem;
		position: relative;
		.beer-head {
			font-family: ${(p) => p.theme.font.display};
			font-size: 1.5rem;
			margin: 0 0 10px;
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			justify-content: flex-start;
			line-height: 1;
			flex-wrap: wrap;

			.beer-head-inner {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				justify-content: flex-start;
				h3 {
					line-height: 1;

					img {
						position: relative;
						top: -6px;
					}
				}
			}
			@media (max-width: 999px) {
				flex-direction: column;
				align-items: center;
				margin-bottom: 5px;

				.beer-head-inner {
					margin-bottom: 10px;
				}
			}
		}

		.beer-desc {
			font-size: 1rem;
			font-family: ${(p) => p.theme.font.body};
			display: block;
		}
		.beer-abv {
			font-size: 1rem;
			font-family: ${(p) => p.theme.font.body};
			font-style: italic;
		}
		.beer-glass {
			height: 40px;
			width: auto;
			position: relative;
			top: 6px;
		}
		@media (max-width: 999px) {
			margin-bottom: 1.5rem;
			.beer-glass.hide-mobile {
				display: none;
			}
		}
	}

	h3.togglehead {
		font-size: 2rem;
		text-align: center;
		text-decoration: underline;
		cursor: pointer;
		padding-left: 1rem;
		margin-bottom: 1rem;
	}

	.taproom-subheading {
		text-align: center;
		margin-bottom: 20px;
		.emphasized {
			font-family: ${(p) => p.theme.font.display};
			font-size: 1.6rem;
			letter-spacing: 0.05em;
		}

		@media (max-width: 999px) {
			margin-bottom: 30px;
		}
	}

	.taps {
		li {
			.beer-head {
				@media (max-width: 999px) {
					flex-direction: row;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
				}
			}
		}
	}

	.buttons {
		display: flex;
		justify-content: center;
		margin-bottom: 2rem;
		button {
			width: 200px;
			&:last-child {
				background: transparent;
			}
		}
	}
	.couch {
		margin-top: 8rem;
		margin-bottom: -1rem;
		width: 100%;
	}
	.links {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		padding: 2rem 0 0 0;

		@media (min-width: 500px) {
			flex-direction: row;
		}

		a {
			color: white;
			text-decoration: underline;
			margin: 0 1rem;
			font-size: 1.25rem;
			font-weight: 700;
		}
	}
	.desc-div {
		margin-bottom: 16px;
		strong {
			color: white;
		}
	}
	.allergy-icons {
		img {
			width: 28px;
			height: 28px;
			display: inline-block;
			margin-right: 8px;
		}
	}
	.legend {
		margin-bottom: 2rem;
		img {
			width: 28px;
			height: 28px;
			display: inline-block;
			margin-right: 8px;
			top: 8px;
			position: relative;
		}
		h3 {
			padding: 0 1rem;
			font-size: 2rem;
			margin-bottom: 1rem;
		}
	}
	.full {
		width: 100%;
		padding: 0 1rem;
	}
`;

const FeatureImageColumns = styled.div`
	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: auto 54%;
		column-gap: 50px;
	}

	.feature-image-column {
		display: none;
		text-align: center;
		padding: 0 5%;

		img {
			width: 250px;
		}

		@media (min-width: 768px) {
			display: block;

			img {
				position: sticky;
				top: 20px;
			}
		}
	}

	.beverages-column {
		@media (min-width: 768px) {
			padding: 50px 0;
		}

		.beer-list {
			padding: 0 1rem;

			@media (min-width: 768px) {
				padding: 0;
			}
		}
	}

	.new-item-callout {
		position: relative;
		width: 43px;
		height: auto;
		margin-left: 0.3rem;
	}

	.two-column-beverage {
		display: grid;
		grid-template-columns: auto 1fr;
		column-gap: 15px;
		margin-bottom: 2.5rem;
		transition: 300ms;

		@media (min-width: 768px) {
			margin-bottom: 1rem;
			column-gap: 25px;
		}

		.image-column {
			/* display: none;

			@media (min-width: 768px) {
				display: block;
			} */
			img {
				width: 60px;
			}
		}

		.details-column {
			.beer-head {
				flex-direction: column;
				align-items: flex-start;
				margin-bottom: 0.5rem;

				@media (min-width: 500px) {
					flex-direction: row;
					align-items: flex-end;
					margin-bottom: 5px;
				}

				.beer-head-inner {
					@media (min-width: 500px) {
						margin-right: 1rem;
					}

					@media (min-width: 768px) {
						max-width: 60%;
					}
				}
				.beer-abv {
					margin-bottom: 0.5rem;

					@media (min-width: 768px) {
						margin-bottom: 0;
					}
				}
			}

			.buy-now-button {
				margin-left: -0.8em;

				&.mobile {
					margin-top: 0.6rem;

					@media (min-width: 500px) {
						display: none;
					}
				}

				&.desktop {
					display: none;

					@media (min-width: 500px) {
						display: block;
					}
				}

				@media (min-width: 500px) {
					margin-left: 0;
				}
			}
		}
	}
`;

const FeatureImageLayout = ({ bevItems }) => {
	const [featuredImage, setFeaturedImage] = useState(bevItems[0].image);

	const handleMouseOver = (item) => {
		setFeaturedImage(item.image);
	};

	return (
		<FeatureImageColumns>
			<div className="feature-image-column">
				<img src={featuredImage.url} alt={featuredImage.alt} />
			</div>
			<div className="beverages-column">
				<ul className="beer-list">
					{bevItems.map((item) => (
						<li
							className="two-column-beverage"
							onMouseOver={() => handleMouseOver(item)}
							key={item.id}
						>
							<div className="image-column">
								<img
									className="beverage-can"
									src={item.image.url}
									alt={item.image.alt}
								/>
							</div>
							<div className="details-column">
								<div className="beer-head">
									<span className="beer-head-inner">
										<h3>{item.name}</h3>
										{item.isNew ? (
											<img
												className="new-item-callout"
												src="/img/menu-call-out-crop.png"
											/>
										) : null}
									</span>
									<span className="beer-abv">{item.abv}</span>
									{item.buyNowLink && item.buyNowLinkIsVisible ? (
										<Button
											className="buy-now-button desktop"
											link={item.buyNowLink.url}
											background={'one'}
											external={true}
											style={{ fontSize: '1.2rem' }}
										>
											Buy Now
										</Button>
									) : null}
								</div>
								<div>
									<span className="beer-desc">{item.shortDescription}</span>
									{item.buyNowLink && item.buyNowLinkIsVisible ? (
										<Button
											className="buy-now-button mobile"
											link={item.buyNowLink.url}
											background={'one'}
											external={true}
											style={{ fontSize: '1.2rem' }}
										>
											Buy Now
										</Button>
									) : null}
								</div>
							</div>
						</li>
					))}
				</ul>
			</div>
		</FeatureImageColumns>
	);
};

export default ({
	headings,
	leftColumn,
	rightColumn,
	onTapDetails,
	beerList,
	seltzerList,
	fridgeList,
	foodList,
	secondaryMenu,
	useOdooTapList,
	showSecondaryMenu,
	secondaryMenuTitle,
}) => {
	// const odooBeers = useStaticQuery(graphql`
	// 	query {
	// 		allTapList {
	// 			edges {
	// 				node {
	// 					beers {
	// 						name
	// 						abv
	// 						desc
	// 						icon
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// `);

	const [primaryMenu, setPrimaryMenu] = useState(true);

	// const beers = useOdooTapList
	// 	? odooBeers.allTapList.edges[0].node.beers
	// 	: beerList;

	const beers = beerList;

	let half = Math.floor(beers.length / 2);
	let firstBeerCol = beers.slice(0, half);
	let secondBeerCol = beers.slice(half, beers.length);

	half = Math.ceil(fridgeList.length / 2);
	let firstFridgeCol = fridgeList.slice(0, half);
	let secondFridgeCol = fridgeList.slice(half, fridgeList.length);

	console.log(showSecondaryMenu);
	console.log(secondaryMenu);

	return (
		<StyledSection>
			<div className="constrain">
				<div className="panel has-border">
					<h1>THE FLYING MONKEYS TAPROOM</h1>
					<div className="details">
						<table>
							<tbody>
								<tr>
									<th dangerouslySetInnerHTML={{ __html: leftColumn.html }} />
								</tr>
							</tbody>
						</table>
						<div dangerouslySetInnerHTML={{ __html: rightColumn.html }} />
					</div>
					<div className="links">
						<Link to="/taproom#taps" style={{ textTransform: 'uppercase' }}>
							{headings.tapSectionHeading}
						</Link>
						<Link to="/taproom#seltzers" style={{ textTransform: 'uppercase' }}>
							{headings.seltzerSectionHeading}
						</Link>
						<Link to="/taproom#fridge" style={{ textTransform: 'uppercase' }}>
							{headings.fridgeSectionHeading}
						</Link>
						<Link to="/taproom#food" style={{ textTransform: 'uppercase' }}>
							{headings.foodSectionHeading}
						</Link>
					</div>
				</div>
				<div className="panel has-border taps" id="taps">
					<h2>{headings.tapSectionHeading}</h2>
					{onTapDetails.heading || onTapDetails.text ? (
						<div className="taproom-subheading">
							<p className="emphasized">{onTapDetails.heading}</p>
							<div dangerouslySetInnerHTML={{ __html: onTapDetails.text }} />
						</div>
					) : null}
					<div className="beer-cols">
						<ul className="beer-list">
							{firstBeerCol.map((beer, i) => (
								<li key={i}>
									<span className="beer-head">
										{i + 1}. {beer.name}
										<img className="beer-glass" src={beer.icon} alt="" />
										<span className="beer-abv">{beer.abv}%</span>
									</span>
									<span className="beer-desc">{beer.desc}</span>
								</li>
							))}
						</ul>
						<ul className="beer-list">
							{secondBeerCol.map((beer, i) => (
								<li key={i}>
									<span className="beer-head">
										{i + 9}. {beer.name}
										<img className="beer-glass" src={beer.icon} alt="" />
										<span className="beer-abv">{beer.abv}%</span>
									</span>
									<span className="beer-desc">{beer.desc}</span>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className="panel has-border" id="seltzers">
					<h2>{headings.seltzerSectionHeading}</h2>
					<FeatureImageLayout bevItems={seltzerList} />
				</div>
				<div className="panel has-border fridge" id="fridge">
					<h2>{headings.fridgeSectionHeading}</h2>
					<div className="beer-cols">
						<ul className="beer-list">
							{firstFridgeCol.map((beer, i) => (
								<li key={i}>
									<div className="beer-head">
										<span className="beer-head-inner">
											<h3>{beer.name}</h3>
											{beer.new ? (
												<img
													className="new-item-callout"
													src="/img/Menu-Call-Out.png"
												/>
											) : null}
										</span>
										<img
											className="beer-glass hide-mobile"
											src={beer.icon}
											alt=""
										/>
										<span className="beer-abv">{beer.abv}</span>
									</div>
									<span className="beer-desc">{beer.desc}</span>
								</li>
							))}
						</ul>
						<ul className="beer-list">
							{secondFridgeCol.map((beer, i) => (
								<li key={i}>
									<div className="beer-head">
										<span className="beer-head-inner">
											<h3>{beer.name}</h3>
											{beer.new ? (
												<img
													className="new-item-callout"
													src="/img/Menu-Call-Out.png"
												/>
											) : null}
										</span>
										<img
											className="beer-glass hide-mobile"
											src={beer.icon}
											alt=""
										/>
										<span className="beer-abv">{beer.abv}</span>
									</div>
									<span className="beer-desc">{beer.desc}</span>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className="panel has-border" id="food">
					<h2>
						{primaryMenu ? headings.foodSectionHeading : secondaryMenuTitle}
					</h2>
					{showSecondaryMenu ? (
						<h3
							className="togglehead"
							onClick={() => setPrimaryMenu(!primaryMenu)}
						>
							{primaryMenu
								? `See ${secondaryMenuTitle} Click Here`
								: 'See regular menu'}
						</h3>
					) : null}
					{primaryMenu ? (
						<ul className="food-list">
							<Sandwich />
							{foodList.map((item, i) => {
								return item.isVisible ? (
									<li key={i + i}>
										<h3>
											{item.title}
											{item.new ? (
												<img
													className="new-item-callout"
													src="/img/Menu-Call-Out.png"
												/>
											) : null}
											<span className="food-price">{item.price}</span>
										</h3>
										<div
											className="desc-div"
											dangerouslySetInnerHTML={{ __html: mdToHtml(item.desc) }}
										/>
										<div className="allergy-icons">
											{item.mustard ? (
												<img
													src="/img/allergy-icons/FM_Allergen_Icons_0000_Mustard.png"
													alt="Contains mustard"
													title="Contains mustard"
												/>
											) : null}
											{item.sesame_seeds ? (
												<img
													src="/img/allergy-icons/FM_Allergen_Icons_0001_Sesame-Seeds.png"
													alt="Contains sesame seeds"
													title="Contains sesame seeds"
												/>
											) : null}
											{item.sulphites ? (
												<img
													src="/img/allergy-icons/FM_Allergen_Icons_0002_Sulphites.png"
													alt="Contains sulphites"
													title="Contains sulphites"
												/>
											) : null}
											{item.soy ? (
												<img
													src="/img/allergy-icons/FM_Allergen_Icons_0003_Soy.png"
													alt="Contains soy"
													title="Contains soy"
												/>
											) : null}
											{item.wheat ? (
												<img
													src="/img/allergy-icons/FM_Allergen_Icons_0004_Wheat.png"
													alt="Contains wheat"
													title="Contains wheat"
												/>
											) : null}
											{item.tree_nuts ? (
												<img
													src="/img/allergy-icons/FM_Allergen_Icons_0005_Tree-Nuts.png"
													alt="Contains tree nuts"
													title="Contains tree nuts"
												/>
											) : null}
											{item.shellfish ? (
												<img
													src="/img/allergy-icons/FM_Allergen_Icons_0006_Shell-Fish.png"
													alt="Contains shellfish"
													title="Contains shellfish"
												/>
											) : null}
											{item.fish ? (
												<img
													src="/img/allergy-icons/FM_Allergen_Icons_0007_Fish.png"
													alt="Contains fish"
													title="Contains fish"
												/>
											) : null}
											{item.peanuts ? (
												<img
													src="/img/allergy-icons/FM_Allergen_Icons_0008_Peanuts.png"
													alt="Contains peanuts"
													title="Contains peanuts"
												/>
											) : null}
											{item.eggs ? (
												<img
													src="/img/allergy-icons/FM_Allergen_Icons_0009_Eggs.png"
													alt="Contains eggs"
													title="Contains eggs"
												/>
											) : null}
											{item.milk ? (
												<img
													src="/img/allergy-icons/FM_Allergen_Icons_0010_Milk.png"
													alt="Contains milk"
													title="Contains milk"
												/>
											) : null}
										</div>
									</li>
								) : null;
							})}
						</ul>
					) : (
						<ul className="food-list">
							{showSecondaryMenu
								? secondaryMenu.map((item, i) => {
										if (item.isVisible) {
											return (
												<li key={i}>
													<h3>
														{item.title}
														{item.new ? (
															<img
																className="new-item-callout"
																src="/img/Menu-Call-Out.png"
															/>
														) : null}
														<span className="food-price">{item.price}</span>
													</h3>
													<div
														className="desc-div"
														dangerouslySetInnerHTML={{
															__html: mdToHtml(item.desc),
														}}
													/>
													<div className="allergy-icons">
														{item.mustard ? (
															<img
																src="/img/allergy-icons/FM_Allergen_Icons_0000_Mustard.png"
																alt="Contains mustard"
																title="Contains mustard"
															/>
														) : null}
														{item.sesame_seeds ? (
															<img
																src="/img/allergy-icons/FM_Allergen_Icons_0001_Sesame-Seeds.png"
																alt="Contains sesame seeds"
																title="Contains sesame seeds"
															/>
														) : null}
														{item.sulphites ? (
															<img
																src="/img/allergy-icons/FM_Allergen_Icons_0002_Sulphites.png"
																alt="Contains sulphites"
																title="Contains sulphites"
															/>
														) : null}
														{item.soy ? (
															<img
																src="/img/allergy-icons/FM_Allergen_Icons_0003_Soy.png"
																alt="Contains soy"
																title="Contains soy"
															/>
														) : null}
														{item.wheat ? (
															<img
																src="/img/allergy-icons/FM_Allergen_Icons_0004_Wheat.png"
																alt="Contains wheat"
																title="Contains wheat"
															/>
														) : null}
														{item.tree_nuts ? (
															<img
																src="/img/allergy-icons/FM_Allergen_Icons_0005_Tree-Nuts.png"
																alt="Contains tree nuts"
																title="Contains tree nuts"
															/>
														) : null}
														{item.shellfish ? (
															<img
																src="/img/allergy-icons/FM_Allergen_Icons_0006_Shell-Fish.png"
																alt="Contains shellfish"
																title="Contains shellfish"
															/>
														) : null}
														{item.fish ? (
															<img
																src="/img/allergy-icons/FM_Allergen_Icons_0007_Fish.png"
																alt="Contains fish"
																title="Contains fish"
															/>
														) : null}
														{item.peanuts ? (
															<img
																src="/img/allergy-icons/FM_Allergen_Icons_0008_Peanuts.png"
																alt="Contains peanuts"
																title="Contains peanuts"
															/>
														) : null}
														{item.eggs ? (
															<img
																src="/img/allergy-icons/FM_Allergen_Icons_0009_Eggs.png"
																alt="Contains eggs"
																title="Contains eggs"
															/>
														) : null}
														{item.milk ? (
															<img
																src="/img/allergy-icons/FM_Allergen_Icons_0010_Milk.png"
																alt="Contains milk"
																title="Contains milk"
															/>
														) : null}
													</div>
												</li>
											);
										} else {
											return null;
										}
								  })
								: null}
						</ul>
					)}
					<div className="legend">
						<h3>Allergy Legend</h3>
						<div className="beer-cols">
							<div className="full">
								<div>
									<img
										src="/img/allergy-icons/FM_Allergen_Icons_0000_Mustard.png"
										alt="Contains mustard"
										title="Contains mustard"
									/>{' '}
									Mustard
								</div>

								<div>
									<img
										src="/img/allergy-icons/FM_Allergen_Icons_0001_Sesame-Seeds.png"
										alt="Contains sesame seeds"
										title="Contains sesame seeds"
									/>{' '}
									Sesame Seeds
								</div>
								<div>
									<img
										src="/img/allergy-icons/FM_Allergen_Icons_0002_Sulphites.png"
										alt="Contains sulphites"
										title="Contains sulphites"
									/>{' '}
									Sulphites
								</div>
								<div>
									<img
										src="/img/allergy-icons/FM_Allergen_Icons_0003_Soy.png"
										alt="Contains soy"
										title="Contains soy"
									/>{' '}
									Soy
								</div>
								<div>
									<img
										src="/img/allergy-icons/FM_Allergen_Icons_0004_Wheat.png"
										alt="Contains wheat"
										title="Contains wheat"
									/>{' '}
									Wheat
								</div>
								<div>
									<img
										src="/img/allergy-icons/FM_Allergen_Icons_0005_Tree-Nuts.png"
										alt="Contains tree nuts"
										title="Contains tree nuts"
									/>{' '}
									Tree Nuts
								</div>
							</div>
							<div className="full">
								<div>
									<img
										src="/img/allergy-icons/FM_Allergen_Icons_0006_Shell-Fish.png"
										alt="Contains shellfish"
										title="Contains shellfish"
									/>{' '}
									Shellfish
								</div>
								<div>
									<img
										src="/img/allergy-icons/FM_Allergen_Icons_0007_Fish.png"
										alt="Contains fish"
										title="Contains fish"
									/>{' '}
									Fish
								</div>
								<div>
									<img
										src="/img/allergy-icons/FM_Allergen_Icons_0008_Peanuts.png"
										alt="Contains peanuts"
										title="Contains peanuts"
									/>{' '}
									Peanuts
								</div>
								<div>
									<img
										src="/img/allergy-icons/FM_Allergen_Icons_0009_Eggs.png"
										alt="Contains eggs"
										title="Contains eggs"
									/>{' '}
									Eggs
								</div>
								<div>
									<img
										src="/img/allergy-icons/FM_Allergen_Icons_0010_Milk.png"
										alt="Contains milk"
										title="Contains milk"
									/>{' '}
									Milk
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<img src={couch} alt="couch" className="couch" />
		</StyledSection>
	);
};
